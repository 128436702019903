import React from 'react';

export const LabeledSection = ({ label, children, actions = null }) => {
    if (!label && !children) {
        return null;
    }

    return (
        <div className="labeled-section">
            {label && <span className="labeled-section__label">{label}</span>}
            {actions && <span className="labeled-section__actions">{actions}</span>}
            {children && children}
        </div>
    );
};
