import React from 'react';

import { SEO } from '../../components/seo';
import { Login } from "../../components/login";

export const SalesPage = () => {
    return (
        <div className="sales-page">
            <SEO title="skills.io - freelancing as it should be" description="Showcase your skills, projects and get hand picked freelancing opportunities." />
            <section className="section">
                <div className="container">
                    <div className="columns">
                        <div className="column">
                            <h1 className="title">skills.io</h1>
                            <h2 className="subtitle">freelancing as it should be</h2>

                            <p className="is-size-3">Showcase your skills, projects and get hand picked freelancing opportunities.</p>
                        </div>
                        <div className="column">
                            <p className="is-size-3">Sign up now. It's free!</p>

                            <Login />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
