import React from 'react';

import { SEO } from '../components/seo';
import { Login } from '../components/login';

export const LoginPage = () => {
    return (
        <div className="login-page">
            <SEO title="Sign in / Sign up" description="Sign in or Sign up - It's free!" />

            <section className="section has-text-centered">
                <h1 className="title">Sign up / Sign in</h1>
            </section>

            <section className="section has-text-centered">
                <Login />
            </section>
        </div>
    );
};

export default LoginPage;
