import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import { SEO } from '../../components/seo';

import { ApplicationContext } from '../../application/context';
import { hasArrayValues } from '../../utils/validations/helpers';

import { Icon } from '../../components/icon';
import { LabeledSection } from '../../components/labeled-section';
import { LoadingIndicator } from '../../components/loading-indicator';

export const Dashboard = () => {
    const { state } = useContext(ApplicationContext);
    const { currentUser } = state;

    if (!currentUser) {
        return null;
    }

    const { skills, projects, profile, services } = currentUser;

    const steps = {
        hasSkills: hasArrayValues(skills),
        hasProjects: hasArrayValues(projects),
        hasHandle: profile && profile.handle && profile.handle.length > 0,
        hasBlurb: profile && profile.blurb && profile.blurb.length > 0,
        hasServices: hasArrayValues(services),
        hasAvailability: profile && Boolean(profile.availability),
        hasLocations: profile && hasArrayValues(profile.locations),
    };

    return (
        <div className="dashboard">
            <SEO title="Your dashboard" description="Your dashboard shows you all sorts of interesting things!" />
            <LoadingIndicator isLoading={!profile}>
                <section className="section">
                    <div className="container">
                        <h1 className="title has-text-centered">Dashboard</h1>

                        <LabeledSection key="hints" label="Next steps">
                            <ProfileCompletionHints steps={steps} />
                        </LabeledSection>
                    </div>
                </section>
            </LoadingIndicator>
        </div>
    );
};

export const ProfileCompletionHints = ({ steps }) => {
    const progress = Object.values(steps);
    const finished = progress.filter(Boolean).length;
    const percentage = Math.round((finished / progress.length) * 100);

    if (progress.length === finished) {
        return (
            <p>
                All done! Whooop!{' '}
                <span role="img" aria-label="party emoji">
                    🥳
                </span>
            </p>
        );
    }

    return (
        <div className="profile-completion-hints">
            <progress className="progress is-info" value={finished} max={progress.length}>
                {percentage}
            </progress>

            <CompletionHint text="Claim your unique user handle" url="/my-profile" finished={steps.hasHandle} />
            <CompletionHint text="Add a short description for yourself" url="/my-profile" finished={steps.hasBlurb} />
            <CompletionHint text="Set your availability status" url="/my-profile" finished={steps.hasAvailability} />
            <CompletionHint text="Add your skills to your profile" url="/my-profile" finished={steps.hasSkills} />
            <CompletionHint text="Add your projects to your profile" url="/my-profile" finished={steps.hasProjects} />
            <CompletionHint text="Add services you provide to your profile" url="/my-profile" finished={steps.hasServices} />
            <CompletionHint text="Add locations you want to work at to your profile" url="/my-profile" finished={steps.hasLocations} />
        </div>
    );
};

export const CompletionHint = ({ text, url, finished }) => {
    const classes = classNames('completion-hint', { 'is-finished': finished });

    return (
        <div className={classes}>
            <Icon icon={finished ? 'fas fa-check-circle' : 'fa fa-clipboard-list'} color={finished ? 'has-text-info' : 'has-text-link'} />
            <span className="completion-hint__text">{finished ? text : <Link to={url}>{text}</Link>}</span>
        </div>
    );
};
