import React, { useContext } from 'react';

import { ApplicationContext } from '../../application/context';

import { Dashboard } from './dashboard';
import { SalesPage } from './salespage';

export const HomePage = () => {
    const { state } = useContext(ApplicationContext);

    const { currentUser } = state;

    return currentUser ? <Dashboard /> : <SalesPage />;
};

export default HomePage;
